@import "../../../theme/globals.scss";

.guest-login-form-wrapper {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 398px;
  padding-top: 2.25em;
  padding-bottom: 1.5em;
  min-height: 380px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  transition: all 1s;

  .checked-in {
    padding-left: 25px;
    padding-right: 25px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .elapsed-time {
      text-align: center;
      font-size: xx-large;
      
    }
  }

  &.exit {
    transform: scale(0.7);
    opacity: 0;
  }

  .text {
    padding-left: 25px;
    padding-right: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #4F444A;
  }

  .contact-form {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 30px;

    .privacy-check {
      display: flex;
      align-items: center;
    }

    .fields {
      display: flex;
      flex-direction: column;
      gap: 35px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    .forgot-passowrd {
      display: flex;
      flex-direction: column;
    }

    span {
      margin-top: auto;
      margin-bottom: auto;
      color: $purple-primary;
      font-weight: 600;
      font-size: 16px;
      align-self: center;
      line-height: 24px;
      margin-left: 10px;
    }

    .confirmation-msg {
      text-align: center;
    }

    .login-button {
      @include Button;
      cursor: pointer;
      margin-top: 20px;
      background-color: $purple-primary;
      border-color: transparent;
      font-weight: 500;
    }

    .error-msg {
      color: red;
    }
  }

  .arrow {
    display: none;
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: 56px;
    }
  }
}
