@import "../../../theme/globals.scss";

.location-form-wrapper {
  padding-left: 0.5em;
  margin-right: 1.6em;
  margin-top: 0.625em;
  padding-bottom: 1em;
  font-family: "Montserrat", sans-serif;

  .category-title {
    margin-top: 1em;
    margin-bottom: 2em;
    margin-left: 0.5em;
    font-weight: 600;
    font-size: 0.875rem;
    color: $brown-primary;
  }

  .grid {
    display: grid;
    gap: 2em;
    grid-template-columns: 45% 50%;
  }

  .inputs-wrapper {
    margin-bottom: 2em;
  }

  .multi-select-wrapper {
    margin-bottom: 4em;

    .search-wrapper {
      min-height: 3.25em;
      padding-top: 0.875em;
      padding-left: 0.875em;
      border-radius: 0.5em;

      &:focus-within {
        border: 2px solid $blue-primary;
        border-radius: 8x;
      }
    }

    .optionListContainer {
      z-index: 100 !important;
    }
  }

  .other-information {
    margin-top: 5em;
  }

  .create-location-button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 50%;
    margin-top: 2.5em;
    margin-bottom: 1.25em;

    .update {
      display: flex;
      justify-content: space-between;
    }
  }
}
