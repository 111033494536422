@import "../../theme/globals.scss";

.login-page-wrapper {
  max-width: 80%;
  margin: auto;
  display: flex;
  position: relative;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;


  .login-page-content {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: auto;
    align-items: center;
    align-content: center;
  }

  .help-text{
    position: absolute;
    color: $neutral;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .tooltip {
    max-width: 300px;
    text-align: center;
    position: absolute;
    font-size: 13px;
    bottom: 200px;
    left: 50%;
    transform: translateX(-50%);
    color: #978F92;
  }
}
