@import "../../../theme/globals.scss";

.home-page-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  height: calc(100vh - #{$header-height});
  align-items: center;
  position: relative;

  padding: 0 124px;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .row-item {
    min-width: 200px;
    max-width: 220px;
    white-space: wrap;
  }

  .field-name {
    color: $black-primary;
    font-family: "Montserrat";
    font-size: 1em;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.00625em;
    text-overflow: ellipsis;
  }

  .field-label {
    font-size: 0.875em;
    line-height: 1.25rem;
    font-weight: 500;
    font-family: "Montserrat";
    color: $gray;
    letter-spacing: 0.00625em;
  }

  .grid-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 0.625em 1em;

    .icon-group {
      display: flex;
      align-items: center;
      gap: 1.265625em;
    }
  }

  .card-category {
    margin-top: 32px;

    .category-title {
      margin-bottom: 11px;
      color: #4b4548;
      font-weight: 600;
      padding-left: 5px;
      font-size: 0.875em;
      line-height: 1.25em;
      font-family: "Montserrat";
      letter-spacing: 0.00625em;
    }
  }

  .help-text {
    position: absolute;
    color: $neutral;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -40%);
    font-family: "Montserrat";
  }
}

.kpi-row {
  display: flex;
  gap: 1.6875em;
  margin-top: 3.5em;
  flex-wrap: wrap;

  .kpi-data {
    font-size: 0.9375em;
    font-weight: 500;
    line-height: 0.75rem;
    letter-spacing: 0.009375rem;
    color: #202a32;
  }

  .kpi-label {
    font-size: 0.8125em;
    font-weight: 400;
    line-height: 1.1625rem;
    letter-spacing: 0.010625rem;
    color: #6f899b;
  }
}

.home-page-wrapper.small-padding {
  padding: 0 26px;
}

.no-align {
  align-items: flex-start;
}

@media only screen and (min-width: 1920px) {
  .home-page-wrapper {
    padding: 0 154px;
  }
}
