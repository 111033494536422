@import "../../theme/globals.scss";

.form-control {
    position: relative;
    width: 100%;
    height: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .form-label {
    position: absolute;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: inherit;
    left: 1rem;
    top: 20px;
    padding: 0 0.25rem;
    color: #4f444a;
    background: white;
    transition: all 0.3s ease;
  }

  .form-input {
    position: absolute;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: inherit;
    top: 0;
    left: 0;
    z-index: 1;
    resize: none;
    width: 100%;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #daced4;
    font-size: 17px;
    color: $black-primary;
    background: transparent;
    transition: all 0.2s ease-in-out;

    &::placeholder {
      opacity: 0;
      visibility: hidden;
      color: transparent;
    }

    &:focus {
      outline: none;
      border: 2px solid #1976D2;

      & ~ .form-label {
        top: -0.5rem;
        left: 1rem;
        z-index: 5;
        font-size: 0.875rem;
        font-weight: 500;
        color: #1976D2;
        transition: all 0.2s ease-in-out;
      }
    }

    &:not(:placeholder-shown).form-input {
      &:not(:focus) {
        & ~ .form-label {
          top: -0.75rem;
          left: 1rem;
          z-index: 9;
          font-size: 0.875rem;
          font-weight: 500;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
