@import "../../../theme/globals.scss";

.gradient-button {
  color: white;
  display: flex;
  gap: 15px;
  align-items: center;
  place-content: center;
  padding-left: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding-right: 30px;
  border: transparent;
  cursor: pointer;
  height: 50px;
  border-radius: 8px;
  text-transform: capitalize;

  &.green {
    background: linear-gradient(90deg, #43e695 0%, #6094ea 100%);
  }

  &.purple {
    background: linear-gradient(90deg, #dd44bc 0%, #6094ea 100%);
  }

  &:disabled {
    background: rgba(31, 31, 31, 0.12);
    color: $black-primary;
    cursor: default;
  }
}
