@import "../../../theme/globals.scss";

.outline-button {
    color: $purple-primary;
    display: flex;
    gap: 15px;
    align-items: center;
    place-content: center;
    padding-left: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding-right: 30px;
    background-color: white;
    border: solid $border-gray;
    cursor: pointer;
    height: 50px;
    border-radius: 8px;
}