.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 50px;
  z-index: 1000;
  color: white;

  &.reveal {
    animation: fadeIn 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &.out {
    animation: fadeOut 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(50%, 50%);
  z-index: 1000;
}

.reveal .modal {
  opacity: 0;
  animation: scaleUp 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.5) translate(-75%, 500%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
