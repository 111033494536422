.generate-report-table {
  height: fit-content;

  .table-row {
    padding: 24px 0 !important;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 12px;
  }
}
