@import "../../theme/globals.scss";

.shared-layout-wrapper {
    .dashboard {
        display: grid;
        grid-template-columns: auto 1fr;
        background-color: $screen-background;

        .dashboard-page {
            width: 100%;
            margin: 0 auto;
         
           
            
           
        }
    }

   
}