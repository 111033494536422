@import "../../theme/globals.scss";

.search-input-container {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 8px;
}

.box {
  width: 250px;
  height: 48px;
  background-color: #f8eef1;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  border-radius: inherit;
}

.box .search-icon {
  min-width: 18px;
  color: $gray;
  padding-left: 22px;
}

.box > input {
  margin-left: 15px;
  flex: 1;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  border: none;
  color: $gray;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}

.search-input-container:focus-within {
  border: 1px solid $border-gray;
}
