@import "../../theme/globals.scss";

.nav-links {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  margin-right: 20px;
  font-size: 17px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;
  color: $gray-primary;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;
  min-height: 56px;
  max-height: 56px;
  box-sizing: border-box;

  .icon {
    margin-right: 1rem;
    margin-left: 1rem;
    color: black;
    transition: inherit;
  }

  &.active {
    background-color: rgba(255, 215, 239, 1);

    .icon {
      color: rgba(221, 68, 188, 1);
    }
  }

  &:hover:not(.active) {
    background-color: rgba(255, 215, 239, 0.45);

    & > .icon {
      color: rgba(221, 68, 188, 0.45);
    }
  }
}
