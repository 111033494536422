@import "../../theme/globals.scss";

.select-div {
  position: relative;
  &:focus-within {
    .label {
      color: $blue-primary;
    }
  }
  .label {
    background-color: white;
    top: -10px;
    font-size: 0.875em;
    font-weight: 500;
    z-index: 2;
    left: 20px;
    color: $gray-primary;
    height: 20px;
    position: absolute;
    bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.select-div.small {
  .label {
    left: 10px;
    font-size: 0.75em;
  }
}
