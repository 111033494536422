@import "../../theme/globals.scss";

.checkbox__wrapper {
  line-height: 33px;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  color: $gray-primary !important;
  display: grid;
  grid-template-columns: 16px auto;
  gap: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    color: $gray-primary !important;
    margin-left: 0 !important;
  }
}

input[type='checkbox'] {
  margin-top: 10px;
  border-radius: 4px;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  color: $blue-primary;
  width: 23px;
  height: 23px;
  border: 2px $blue-primary solid;
  transform: translateY(5px);
  display: grid;
  place-content: center;
  // transition: var(--transition-input);
  cursor: pointer;

  &:hover {
    border: 2px $blue-secondary solid;
  }

  &:checked {
    border: 2px $blue-primary solid;
  }
}

input[type='checkbox']::before {
  content: '';
  width: $input-checkbox-before-size;
  height: $input-checkbox-before-size;
  transform: scale(0);
  // transition: var(--transition-input);
  box-shadow: inset $input-checkbox-before-size $input-checkbox-before-size $blue-primary;
  clip-path: $checkmark-polygon;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

a {
  text-decoration: underline;
  color: $purple-primary;
}