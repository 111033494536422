@import "../../../theme/globals.scss";

.reports-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 3em;
  align-items: center;
  place-items: center;
  flex-direction: column;

  .report-title {
    align-items: start;
    width: 65vw;
    margin-top: 1em;
    font-family: "Montserrat", "sans-serif";
    font-weight: 600;
    font-size: 1.75rem;
    color: $brown-primary;
  }
}
