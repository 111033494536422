.card-wrapper {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  background-color: #ffffff;
  width: 65vw;
  padding: 1.0625em 1.5em;
  font-family: "Montserrat", sans-serif;
}

.card-wrapper.small-padding {
  padding: 0.5em;
}

.card-wrapper.flex {
  display: flex;
  gap: 0.375em;
  flex-direction: column;
  justify-content: center;
}

.card-no-data {
  height: 100%;
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
}
