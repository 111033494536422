@import  "../../../theme/globals.scss";

.unlink-modal-main {
    min-width: 300px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: $gray-primary;

    .button-container {
     display: flex;
     justify-content: space-between;
     width: 100%;
    }
}