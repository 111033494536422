//Colors
$neutral: #43383f;
$purple-primary: #dd44bc;
$purple-secondary: #ffecf5;
$gray-primary: #4f444a;
$black-primary: #1f1a1d;
$screen-background: #f7f3fa;
$blue-primary: #1976d2;
$blue-secondary: #3399ff;
$border-gray: #daced4;
$brown-primary: #4b4548;
$neutral-gray: #43383f;
$gray: #978f92;

//Sizes
$header-height: 4.5em;
$user-nav-width: 13.1875em;

// CHECKBOX VARS
$input-checkbox-before-size: 20px;
$checkmark-polygon: polygon(77% 16%, 88% 23%, 45% 85%, 10% 56%, 19% 45%, 42% 64%);


//Mixins
@mixin Button {
  border-radius: 8px;
  height: 40px;
  color: white;
  font-size: 14px;
  line-height: 20px;
}

@mixin ModalTitle {
  color: $black-primary;
  font-size: 16px;
  font-family: "Montserrat", "sans-serif";
  padding-left: 4px;
  margin-bottom: 15px;
  font-weight: 600;
}
