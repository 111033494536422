@import "../../../theme/globals.scss";

.insert-network {
    color: $black-primary;
    font-size: 16px;
    font-family: 'Montserrat', 'sans-serif';
    padding-left: 4px;
    margin-bottom:15px;
    font-weight: 600;
}

.networks-container {
       
    scrollbar-width: 2px; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    
    &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
    }
}