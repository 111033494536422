@import "../../../theme/globals.scss";

.organisations-card-wrapper {
  padding-left: 8px;
  margin-right: 27px;
  margin-top: 10px;
  padding-bottom: 1em;
  font-family: "Montserrat", sans-serif;

  .category-title {
    margin-top: 16px;
    margin-bottom: 32px;
    margin-left: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #4b4548;

    &.network {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }

  .inputs-wrapper {
    margin-bottom: 32px;

    .phone-input {
      border-radius: 7px;
      width: 100%;
      color: $gray-primary;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .name {
    margin-bottom: 25px;
  }

  .phone {
    margin-bottom: 20px;
  }

  .network-input-wrapper {
    margin-bottom: 13px;

    .label {
      margin-top: 2px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .type-button-wrapper {
    margin-top: 30px;
  }

  .toggle-button-wrapper {
    margin-bottom: 32px;
    display: flex;

    align-items: center;
    justify-content: space-between;
  }

  .grid {
    display: grid;
    gap: 32px;
    row-gap: 0;
    grid-template-columns: 45% 50%;
  }

  .checkbox-location-wrapper {
    display: flex;
    gap: 35px;
    align-items: center;
    align-content: center;
  }

  .network-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 5px;

    & > button {
      flex: 1 1 auto;
    }
  }

  .create-organisation {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 50%;
    margin-top: 40px;
    margin-bottom: 20px;

    .update {
      display: flex;
      justify-content: space-between;
    }
  }

  //css for input library
  .search-wrapper {
    min-height: 52px;
    padding-top: 14px;
    padding-left: 16px;
    border-radius: 8px;

    &:focus-within {
      border: 2px solid $blue-primary;
      border-radius: 8x;
    }
  }
}

#search_input.searchBox {
  &::placeholder {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Montserrat", "sans-serif";
    color: $gray-primary;
  }
}
