.manage-people-main {
    padding-left: 154px;
    padding-right: 154px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-height: calc(100vh - #{400px});
    justify-content: space-between;
}