@import "../../theme/globals.scss";

.header-wrapper {
  height: $header-height;
  max-height: $header-height;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.6875em;
  padding-right: 3em;
  font-family: "Montserrat", sans-serif;
}

.page-title {
  color: $gray-primary;
  font-weight: 600;
  font-size: 1.75em;
  line-height: 2.25rem;
}

.user-nav {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.00625em;
  font-weight: 600;
  position: relative;

  .user-picture {
    border-radius: 50%;
    width: 3.25em;
    max-width: 3.25em;
    height: 3.25em;
    max-height: 3.25em;
    background-color: $border-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    overflow: hidden;

    & > img {
      height: inherit;
      width: inherit;
      border-radius: inherit;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    padding-right: 0.9375em;

    .user-name {
      font-size: 1.125em;
      line-height: 1.5rem;
    }

    .user-role {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 1.25rem;
    }
  }

  .menu-icon {
    cursor: pointer;
    padding: 0.3125em;
    border: 1px solid transparent;
    border-radius: 25px;

    &.active,
    &:hover {
      border: 1px solid $border-gray;
    }
  }
}
