@import "../../../theme/globals.scss";

$dropdown-padding: 1em;

.user-nav-dropdown {
  position: absolute;
  top: $header-height;
  list-style-type: none;
  background-color: white;
  width: $user-nav-width;
  padding: $dropdown-padding;
  border-radius: 1em;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-top: 0.5em;
  user-select: none;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5rem;
  letter-spacing: 0.0125em;
}

.org-info {
  padding-bottom: 0.5rem;

  & span {
    display: block;
  }

  .org-title {
    color: $gray;
  }

  .org-code {
    display: flex;
    gap: 1rem;
    line-height: 1.75em;
    font-size: 1.375em;
    font-weight: 600;
    color: $black-primary;
  }

  .copy-icon {
    cursor: pointer;
    transition: all 0.03s ease-in-out;

    &:active {
      transform: scale(1.25);
    }
  }
}

.divider {
  position: relative;
  left: -$dropdown-padding;
  width: calc(100% + (#{$dropdown-padding} * 2));
  height: 0.0625em;
  background-color: #eae0e3;
  margin-bottom: 0.8125em;
}

.user-nav-link {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  height: 2.8125em;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.15s ease-in-out;

  & > img {
    padding-left: 0.125em;
    padding-right: 1.125em;
  }

  &:hover {
    text-decoration-color: $border-gray;
  }
}
