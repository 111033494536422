.add-organisation-wrapper {
  height: 94vh;
  display: flex;
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  line-height: 1.25;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    place-content: center;
    flex-direction: column;
    gap: 1em;
  }
}
