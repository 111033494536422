@import "../.././../theme/globals.scss";

.create-network {
  .modal-title {
    @include ModalTitle;
  }

  .field-container {
    margin-top: 30px;
  }

  .buttons-group {
    margin-top: 0.825em;
    display: flex;
    gap: 10px;
  }

  .multi-select-container {
    margin-top: 40px;

    .search-wrapper {
      min-height: 52px;
      padding-top: 14px;
      padding-left: 16px;
      border-radius: 8px;

      #search_input.searchBox {
        &::placeholder {
          font-size: 1rem;
          font-weight: 400;
          font-family: "Montserrat", "sans-serif";
          color: $gray-primary;
        }
      }

      &:focus-within {
        border: 2px solid $blue-primary;
        border-radius: 8x;
      }
    }
  }
}
