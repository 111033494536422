@import "../../theme/globals.scss";

.login-page-wrapper {
  max-width: unset;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  display: flex;
  position: relative;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;


  .login-page-content {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: auto;
    align-items: center;
    align-content: center;

    .avatar-box {
      width: 275px;
    }
  }

  .help-text{
    position: absolute;
    color: $neutral;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 950px) {
  .login-page-wrapper {
    .guest-login-form-wrapper {
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media (max-width: 768px) {
  .login-page-wrapper {

    .login-page-content {
      flex-direction: column;
      padding-bottom: 4em;

      .avatar-box {
        flex-direction: row;

        .avatar-box-left-second {
          position: unset;
          align-self: flex-start;
          transform: unset;
        }
      }
    }

    .guest-login-form-wrapper {
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media (max-width: 435px) {
  .login-page-wrapper {
    .guest-login-form-wrapper {
      width: auto;
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 10px;
      padding-right: 10px;

      .contact-form {
        width: auto;
      }
    }
  }
}