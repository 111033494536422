.tab-list {
  padding: 0;
  display: flex;
  background-color: white;
  margin-top: 0;
  border: solid 1px;
  border-color: transparent;
  border-top-color: #eae0e3;
  border-bottom-color: #eae0e3;
  height: 42px;
}

.tab-content {
  padding: 0 1rem;
}

.tab-content p {
  text-align: justify;
}

li.tab-item {
  list-style-type: none;
  padding-top: 12px;
  position: relative;
  z-index: 2;
  background-color: white;
  font-weight: bold;
  letter-spacing: 0.1rem;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #7c7578;
  font-size: 14px;
  margin-left: 32px;
  transition: all 0.5s ease-in-out;
}

li.tab-item:hover,
li.tab-item.active {
  background-color: white;
  z-index: 2;
//   border-bottom: 2px solid #DD44BC;
  // border-bottom-width: 120%;
color: #DD44BC
}

.active-underline {
  position: absolute;
  height: 2px;
  width: 120%;
  bottom: -3%;
  left: -5%;
  transition: all 0.5s ease-in-out;
  background-color: #dd44bc;
}
