.location-usage-wrapper {
  .pie-bullets {
    display: flex;
    gap: 2em;
    place-items: flex-end;
    place-content: flex-end;
    align-items: center;
    margin-right: 1.2em;
    font-size: 0.75em;
    font-weight: 500;
    font-family: "Montserrat", "sans-serif";

    .bullet {
      display: flex;
      align-items: center;
      gap: 0.6em;

      .green {
        width: 1.4em;
        height: 1.4em;
        border-radius: 3.375em;
        background-color: #35c5af;
      }

      .purple {
        width: 1.4em;
        height: 1.4em;
        border-radius: 3.375em;
        background-color: #f030c1;
      }
    }
  }

  .pies-group {
    display: flex;
    place-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
}
