.avatar-box {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 170px;

  & .avatar-box-right-first {
    align-self: center;
  }

  & .avatar-box-right-second {
    align-self: flex-end;
    padding-top: 20px;
  }

  & .avatar-box-right-third {
    padding-left: 20px;
  }

  & .avatar-box-left-first {
    align-self: flex-end;
  }

  & .avatar-box-left-second {
    align-self: flex-start;
    transform: translate(-10px, 30px);
    position: absolute;
  }

  & .avatar-box-left-third {
    padding-top: 15px;
    align-self: center;
    padding-right: 30px;
  }
}
