@import "../../theme/globals.scss";

.table-main {
    width: 100%;
    height: 100%;
    min-height: 150px;
    margin-bottom: 15px;
    margin-top: 15px;

    display: flex;
    flex-direction: column;

    background: #ffffff;

    /* Drop Shadow */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    border-radius: 16px;

    .table-header {
        display: flex;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 6px;
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #eae0e3;

        max-height: 65px;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: $gray-primary;

        span {
            margin-top: auto;
            margin-bottom: auto;
        }

        .actions {
            display: flex;
            gap: 8px;
        }
    }

    .table-rows-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 10px;

        .table-no-data {
            height: 100%;
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 36px;
            /* identical to box height, or 129% */

            display: flex;
            align-items: center;

            /* M3/sys/light/on-surface-variant */

            color: #4f444a;
            padding-right: 24px;
        }

        .table-subheader {
            display: flex;
            flex-direction: row;
            max-height: 40px;

            .table-subheader-cell {
                width: 100%;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                color: $gray;
                text-transform: uppercase;
                padding-top: 20px;
            }

            .action {
                min-width: 67px;
                max-width: 70px !important;
                width: fit-content;
                margin-left: 10px;
                margin-right: 10px;
                text-align: center;
            }
        }

        .table-row {
            min-height: 45px;
            display: flex;
            border-bottom: 1px solid #eae0e3;
            // padding-left: 15px;

            &.selectable {
                cursor: pointer;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: $purple-secondary;
                }
            }

            .table-row-cell {
                margin-top: auto;
                margin-bottom: auto;
                width: 100%;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: $gray-primary;
            }

            .action {
                min-width: 87px;
                max-width: 70px !important;
                position: relative;
                padding-top: 25px;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-45%);
                    cursor: pointer;
                }
            }

            .action:last-of-type {
                padding-top: 0;
                display: flex;
                padding-right: 0;
                margin-right: 0;

                svg {
                    width: fit-content;
                    margin-bottom: 10px;
                    position: inherit;
                    margin-left: 5px;
                }
            }

            .padded {
                margin-right: 14px !important;
            }

            .two-icons {
                svg {
                    margin-left: 0 !important;
                    margin-right: 10px;
                }
            }
        }

        .table-row:last-of-type {
            border-bottom: 0px;
        }
    }
}
