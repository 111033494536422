@import "../../../theme/globals.scss";

.login-form-wrapper {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 398px;
  padding-top: 3.75em;
  padding-bottom: 1.5em;
  min-height: 380px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  transition: all 1s;

  &.exit {
    transform: scale(0.7);
    opacity: 0;
  }

  .text {
    margin-top: 18px;
    background: linear-gradient(90deg, #dd44bc 0%, #6c93e4 40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
    font-size: 16.2453px;
    line-height: 20px;
    margin-bottom: 6px;
  }

  .contact-form {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 35px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    .forgot-passowrd {
      display: flex;
      flex-direction: column;
    }

    span {
      color: $purple-primary;
      font-weight: 600;
      font-size: 16px;
      align-self: center;
      line-height: 24px;
      margin-top: 25px;
    }

    .confirmation-msg {
      text-align: center;
    }

    .login-button {
      @include Button;
      cursor: pointer;
      margin-top: 20px;
      background-color: $purple-primary;
      border-color: transparent;
      font-weight: 500;
    }

    .error-msg {
      color: red;
    }
  }

  .arrow {
    display: none;
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: 56px;
    }
  }
}
