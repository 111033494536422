.pie-wrapper {
  width: 26%;
  height: 25.875em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25em;

  .month {
    font-family: "Montserrat", "sans-serif";
    font-size: 0.75rem;
    font-weight: 500;
  }
}
