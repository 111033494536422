@import "../../theme/globals.scss";

.network-wrapper {
    display: flex;
    align-items: center;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 500;
    padding-left: 10px;
    background-color: $purple-secondary; 
    color: $purple-primary;
    margin-bottom: 5px;
    height: 52px;
    border-radius: 8px;
    gap: 17px;
    cursor: pointer;
}