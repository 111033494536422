.sidebar-wrapper {
  min-height: 100vh;
  z-index: 2;

  .sidebar-container {
    background-color: white;
    height: 100%;
    width: 245px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  }

  .trimmed-sidebar-container {
    background-color: white;
    height: 80%;
    width: 245px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  }

  .content {
    padding-left: 24px;
    padding-top: 20px;
  }
}
